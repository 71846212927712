.notice-wrap {
  width: 100%;
  border-top: 1px solid #c8c8d5;
}

.notice-wrap:last-of-type {
  border-bottom: 1px solid #c8c8d5;
}

.bg-maintenance {
  background-color: #7f8a92;
  color: #ffffff;
}

.bg-maintenance-clicked {
  background-color: #ddf1ff;
  color: #382020;
}

.bg-event {
  background-color: #5f35ae;
  color: #f4eaff;
}

.bg-event-clicked {
  background-color: #f4eaff;
  color: #5f35ae;
}

.bg-update {
  background-color: #38aa0f;
  color: #ffffff;
}

.bg-update-clicked {
  background-color: #c2f1ac;
  color: #21480c;
}
