.order-list {
    padding-left: 25px
}

span {
    display: inline-block;
}

.lists {
    list-style: decimal;
    margin-top: 15px;
}

.ol-in-list {
    display: list-item;
    list-style: lower-alpha;
    margin-top: 15px;
}