th {
    max-width: 350px;
    font-weight: bold;
    text-align: start;
    background-color: #f5f5f5;
    padding: 10px 20px 10px 20px;
    border: 1px solid #cecece;
}

td {
    padding: 10px 20px 10px 20px;
    border: 1px solid #cecece;
}