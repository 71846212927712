.header {
    background-color: #f5f5f5;
}

th {
    max-width: 350px;
    font-weight: bold;
    text-align: start;
    padding: 20px 40px 20px 40px;
    border: 1px solid #cecece;
}

td {
    padding: 20px 40px 20px 40px;
    border: 1px solid #cecece;
}